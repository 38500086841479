/* Snow container to cover the entire viewport */
.snow-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none; /* Allows user interactions to pass through */
  z-index: 1; /* Ensures snowflakes stay in the background */
}

/* Snowflake styling */
.snowflake {
  position: absolute;
  top: -5vh;
  color: white;
  animation: snowfall linear infinite;
}

/* Snowfall animation */
@keyframes snowfall {
  0% {
    transform: translate3d(var(--left-ini), 0, 0);
  }
  100% {
    transform: translate3d(var(--left-end), 110vh, 0);
  }
}
