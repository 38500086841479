.heartContainer {
    position: absolute;
    width: 100px;
    height: 90px;
    margin-top: 10px;
    transform: translate(-50%, 0) scale(0);
    opacity: 0;
    animation: bubbleUp 2s forwards;
    pointer-events: none;
    z-index: -1;
  }
  .heartRight {
    transform: translateX(12.5vw) rotate(25deg);
    animation: oscillateRight 2s ease-in-out forwards;
  }
  .heartLeft {
    transform: translateX(-12.5vw) rotate(-25deg);
    animation: oscillateRight 2s ease-in-out forwards;
  }
  
  .heart::before,
  .heart::after {
    content: "";
    position: absolute;
    top: 0;
    width: 52px;
    height: 80px;
    border-radius: 50px 50px 0 0;
    background: rgb(255, 34, 71);
  }
  
  .remoteHeart::before,
  .remoteHeart::after {
    background: rgb(255, 123, 191);
  }
  
  .heart::before {
    left: 50px;
    transform: rotate(-45deg);
    transform-origin: 0 100%;
  }
  
  .heart::after {
    left: 0;
    transform: rotate(45deg);
    transform-origin: 100% 100%;
  }
  
  @keyframes bubbleUp {
    50% {
      opacity: 1;
    }
    100% {
      transform: translate(-25%, -35vh) scale(1);
    }
  }
  @keyframes oscillateRight {
    100% {
      transform: translateX(-3.5vw) rotate(-25deg);
    }
  }
  @keyframes oscillateLeft {
    100% {
      transform: translateX(3.5vw) rotate(-25deg);
    }
  }