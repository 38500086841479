@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Montserrat:wght@700&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100vh;
  width: 100vw;
}

.app {
  background-color: purple;
  background: url(../public/backgrounds/greece.webp) no-repeat center center
    fixed;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  color: white;
  font-family: "Open Sans", sans-serif;
  height: 100vh;
  text-align: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.header {
  font-size: calc(40px + 3vmin);
  line-height: 1;
  text-transform: capitalize;
  font-family: "CustomFont";
  color: #fff;
  text-shadow: 2px -2px 0 rgba(0, 0, 0, 1), -2px 2px 0 rgba(0, 0, 0, 1),
    -2px -2px 0 rgba(0, 0, 0, 1), 2px 2px 0 rgba(0, 0, 0, 1),
    2px 0 0 rgba(0, 0, 0, 1), -2px 0 0 rgba(0, 0, 0, 1),
    0 2px 0 rgba(0, 0, 0, 1), 0 -2px 0 rgba(0, 0, 0, 1),
    1px 1px 0 rgba(0, 0, 0, 1), -1px -1px 0 rgba(0, 0, 0, 1),
    -1px 1px 0 rgba(0, 0, 0, 1), 1px -1px 0 rgba(0, 0, 0, 1),
    1px 0 0 rgba(0, 0, 0, 1), -1px 0 0 rgba(0, 0, 0, 1),
    0 1px 0 rgba(0, 0, 0, 1), 0 -1px 0 rgba(0, 0, 0, 1),
    -7px 7px 3px rgba(0, 0, 0, 0.8);
  transform: rotate(-5deg);
  user-select: none;
  z-index: 9;
}

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.timer {
  background-color: rgba(0, 0, 0, 0.5);
  display: inline-block;
  margin-top: 20px;
  padding: 10px;
  text-align: center;
  width: 500px;
  max-width: 100%;
  min-width: 330px;
  user-select: none;
  z-index: 10;
}

.connectionCounter {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px;
  position: fixed;
  bottom: 0;
  right: 0;
  color: #fff;
}

.col-4 {
  width: 25%;
  float: left;
}

.box {
  border-right: solid 1px rgba(255, 255, 255, 0.3);
  font-weight: 300;
  padding: 10px;
  cursor: pointer;
}

.last {
  border-right-color: transparent;
}

.box p {
  font-size: calc(14px + 2vmin);
  margin: 0;
}

.text {
  font-size: 16px;
}

@font-face {
  font-family: "CustomFont";
  src: url("/public/GreatVibes-Regular.ttf");
}

@keyframes custom-fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  height: 100%;
  width: 100%;
  z-index: 9999;
  display: block;
  opacity: 1;
}

.hidden {
  animation-name: custom-fadeout;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}
